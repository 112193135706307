import React, { useEffect, useState } from 'react'
import NavbarCheques from '../components/NavbarCheques'
import { useSpring, animated } from "react-spring"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { BsFillArrowDownCircleFill } from "react-icons/bs";
import { useDispatch, useSelector } from 'react-redux'
import { simuladorCheque } from '../Redux/simuladorCheques'
import { Toast, Spinner } from 'react-bootstrap'
import { RiWhatsappFill } from "react-icons/ri";

function SimuladorCheques() {
    const dispatch = useDispatch()
    const [show, setShow] = useState(false)
    const [mensaje, setMensaje] = useState('')
    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(false)
    const [montoCheque, setMontoCheque] = useState('')
    const [plazoDias, setPlazoDias] = useState('')
    const [tasaInteres, setTasaInteres] = useState('')

    // const resultadoCheque = useSelector(store => store.cheque.resultado)
    // const mensajeCheque = useSelector(store => store.cheque.resultadoConsulta)


    /* const respuesta */
    // const [interes, setInteres] = useState('')
    // const [importeBruto, setImporteBruto] = useState('')
    // const [comisionSGR, setComisionSGR] = useState('')
    // const [arancelCaja, setArancelCaja] = useState('')
    // const [arancelAgente, setArancelAgente] = useState('')
    // const [derechoMercado, setDerechoMercado] = useState('')
    // const [montoNeto, setMontoNeto] = useState('')
    // const [totalComisiones, setTotalComisiones] = useState('')
    // const [descuentoNeto, setDescuentoNeto] = useState('')
    // const [cFTAnual, setCFTAnual] = useState('')
    // const [iVASobreCargos, setIVASobreCargos] = useState('')
    const [montoNetoCobrar, setMontoNetoCobrar] = useState('')


    useEffect(() => {
        if (montoNetoCobrar != undefined) {
            // setInteres(resultadoCheque.new_interes)
            // setImporteBruto(resultadoCheque.new_importebruto)
            // setComisionSGR(resultadoCheque.new_montoacomisionarporlasgr)
            // setArancelCaja(resultadoCheque.new_montoarancelcajadevaloressa)
            // setArancelAgente(resultadoCheque.new_montoarancelagentedemercado)
            // setDerechoMercado(resultadoCheque.new_montoderechodemercado)
            // setMontoNeto(resultadoCheque.new_totalcomisionesygastos)
            // setTotalComisiones(resultadoCheque.new_montoneto)
            // setDescuentoNeto(resultadoCheque.new_descuentoneto)
            // setCFTAnual(resultadoCheque.new_cftanual)
            // setIVASobreCargos(resultadoCheque.new_ivasobrecargos)
            setMontoNetoCobrar(montoNetoCobrar)
        }

        // if (mensajeCheque !== undefined) {
        //     if (mensajeCheque !== '') {
        //         cargaExito(mensajeCheque)
        //     }
        // }

    }, [montoNetoCobrar])

    async function simuladorCheque(montoCheque, plazoDias, tasaInteres) {
        debugger
        setMontoNetoCobrar((montoCheque - (montoCheque * ((tasaInteres * 1) / 110)) * (plazoDias / 360)).toFixed(2))
        document.getElementById("btnArrow").click()
    }

    console.log(montoNetoCobrar)

    async function CalcularCheque(e) {
        e.preventDefault()
        if (!montoCheque.trim()) {
            setShow(true)
            setError(true)
            setMensaje("Ingrese el monto del cheque")
            document.getElementById("montoCheque").classList.add("is-invalid");
            return
        } else {
            setShow(false)
            setError(false)
            document.getElementById("montoCheque").classList.remove('is-invalid')
        }
        if (!plazoDias.trim()) {
            setShow(true)
            setError(true)
            setMensaje("Ingrese el plazo el dias")
            document.getElementById("plazoDias").classList.add("is-invalid");
            return
        } else {
            setShow(false)
            setError(false)
            document.getElementById("plazoDias").classList.remove('is-invalid')
        }
        if (!tasaInteres.trim()) {
            setShow(true)
            setError(true)
            setMensaje("Ingrese la tasa de interés")
            document.getElementById("tasaInteres").classList.add("is-invalid");
            return
        } else {
            setShow(false)
            setError(false)
            document.getElementById("tasaInteres").classList.remove('is-invalid')
        }
        simuladorCheque(montoCheque, plazoDias, tasaInteres)
        // setMensaje("Cargando...")
        // setLoading(true)
        // setShow(true)
        // setExito(true)
        limpiarForm()
    }

    // const cargaExito = (resultado) => {
    //     if (resultado === "EXITO") {
    //         setMensaje("La consulta fue éxitosa!")
    //         setError(false)
    //         setLoading(false)
    //         setShow(true)
    //         setTimeout(() => {
    //             setShow(false)
    //         }, 2000)
    //         setTimeout(() => {
    //             document.getElementById("btnArrow").click();
    //         }, 3000);
    //     }
    //     else if (resultado === "ERROR") {
    //         setMensaje("Error en la consulta!")
    //         setError(true)
    //         setLoading(false)
    //         setShow(true)
    //         setTimeout(() => {
    //             setShow(false)
    //         }, 3000);
    //     }
    // }

    const limpiarForm = () => {
        setMontoCheque('')
        setPlazoDias('')
        setTasaInteres('')
    }
    const fade = useSpring({
        from: {
            opacity: 1,
        },
        to: {
            opacity: 1,
            delay: 1500,
        },
    });

    return (
        <>
            <div className='portadaCheques'>
                <NavbarCheques />
                <div className='formularioCheques'>
                    <h4 className='tituloCheques'>Simulador de Cheques</h4>
                    <form className='formInputs' onSubmit={CalcularCheque}>
                        <div className="mb-3 col-4">
                            <label htmlFor="montoCheque" className="form-label text-light">Ingrese el Monto</label>
                            <input
                                type="number"
                                className="form-control"
                                id="montoCheque"
                                aria-describedby="monto"
                                onChange={e => setMontoCheque(e.target.value)}
                                value={montoCheque}
                            />
                        </div>
                        <div className="mb-3 col-4">
                            <label htmlFor="plazoDias" className="form-label text-light">Ingrese el plazo en dias</label>
                            <input
                                type="number"
                                className="form-control"
                                id="plazoDias"
                                onChange={e => setPlazoDias(e.target.value)}
                                value={plazoDias}
                            />
                        </div>
                        <div className="mb-3 col-4">
                            <label htmlFor="tasaInteres" className="form-label text-light">Tasa de interés</label>
                            <input
                                type="number"
                                className="form-control"
                                id="tasaInteres"
                                onChange={e => setTasaInteres(e.target.value)}
                                value={tasaInteres}
                            />
                        </div>
                        <button type="submit" className="btn colorBtn">Calcular</button>
                        <a
                            className='btn'
                            href="#abajo"
                            id='btnArrow'
                        >
                            <BsFillArrowDownCircleFill className='arrow' />
                        </a>
                    </form>
                </div>
                <animated.div className="row" style={fade}>
                    <div className="row">
                        <div className="col-4 position-fixed bottom-0 end-0 p-5 noti">
                            <Toast className="half-black" show={show} autohide color="lime">
                                <Toast.Body className="text-white">
                                    <div className="row p-2">
                                        {
                                            loading ?
                                                <Spinner animation="border" role="status" variant="primary">
                                                    <span className="visually-hidden">Loading...</span>
                                                </Spinner>
                                                :
                                                <div className="col-1 mx-2">
                                                    {error ? <FontAwesomeIcon icon={faTimesCircle} className="fs-3 upload-file atras" color="#dc3545" /> : <FontAwesomeIcon icon={faCheckCircle} className="fs-3 upload-file atras" color="#198754" />}
                                                </div>
                                        }

                                        <div className="col-10 mt-1 ml-5">
                                            {mensaje}
                                        </div>
                                    </div>
                                </Toast.Body>
                            </Toast>
                        </div>
                    </div>
                </animated.div>
            </div>
            <div id='abajo'></div>
            <br /><br /><br />
            <div className='resultadoApiCheque container mb-5'>
                {/* <div className='d-flex justify-content-between br my-2'>
                    <p className='mb-1'>Interés</p>
                    <p className='mb-1 colorVerde'>$ {interes}</p>
                </div>
                <div className='d-flex justify-content-between br my-2'>
                    <p className='mb-1'>Importe bruto</p>
                    <p className='mb-1 colorVerde'>$ {importeBruto}</p>
                </div>
                <h5 className='my-3'>Comisiones y gastos</h5>
                <div className='d-flex justify-content-between br my-2'>
                    <p className='mb-1'>Comisión SGR</p>
                    <p className='mb-1 colorVerde'>$ {comisionSGR}</p>
                </div>
                <div className='d-flex justify-content-between br my-2'>
                    <p className='mb-1'>Arancel Caja de Valores SA (solo para valores al cobro)</p>
                    <p className='mb-1 colorVerde'>$ {arancelCaja}</p>
                </div>
                <div className='d-flex justify-content-between br my-2'>
                    <p className='mb-1'>Arancel Agente de Mercado</p>
                    <p className='mb-1 colorVerde'>$ {arancelAgente}</p>
                </div>
                <div className='d-flex justify-content-between br my-2'>
                    <p className='mb-1'>Derecho Mercado</p>
                    <p className='mb-1 colorVerde'>$ {derechoMercado}</p>
                </div>
                <div className='d-flex justify-content-between br my-2'>
                    <p className='mb-1'>Total Comisiones y gastos</p>
                    <p className='mb-1 colorVerde'>$ {montoNeto}</p>
                </div>
                <div className='d-flex justify-content-between br my-2'>
                    <p className='mb-1 fw-bold'>Monto neto liquidación cheques</p>
                    <p className='mb-1 colorVerde'>$ {totalComisiones}</p>
                </div>
                <div className='d-flex justify-content-between br my-2'>
                    <p className='mb-1'>DESCUENTO NETO</p>
                    <p className='mb-1 colorVerde'>{descuentoNeto}%</p>
                </div>
                <div className='d-flex justify-content-between br my-2'>
                    <p className='mb-1'>CFT ANUAL</p>
                    <p className='mb-1 colorVerde'>{cFTAnual}%</p>
                </div>
                <div className='d-flex justify-content-between br my-2'>
                    <p className='mb-1'>IVA sobre cargos</p>
                    <p className='mb-1 colorVerde'>$ {iVASobreCargos}</p>
                </div> */}
                <div className='d-flex justify-content-between br my-2'>
                    <p className='mb-1 fw-bold'>Monto neto aliquidación a cobrar (Aproximado)</p>
                    <p className='mb-1 colorVerde'>$ {montoNetoCobrar}</p>
                </div>
                <h6>Este valor puede variar dependiendo de las tasas de negociación del mercado.
                    Si usted necesita un cálculo más exacto, por favor contáctese al +541152635747
                    vía WhatsApp o envíe un mail a <a href="mailto:creditos@alianzasgr.com.ar">creditos@alianzasgr.com.ar</a></h6>
            </div>
        </>
    )
}

export default SimuladorCheques