import React from 'react'
import { useSpring, animated } from "react-spring";
import { BsFileEarmarkPdfFill, BsGlobe2 } from "react-icons/bs";
import Navbar from "../components/Navbar";
import Footer from '../components/Footer'
import imgCredito from '../img/Credito.png'
import imgCheques from '../img/Cheques.png'
import imgComision from '../img/Comision.png'
import queEsSGR from '../img/Que es SGR.png'
import valores from '../img/Valores.png'
import MartinRappallini from '../img/Martin.png'
import RobertoCostaBourdieu from '../img/Robert.png'
import InstructivoCargaDeEcheqs from '../docs/EpymeIngresodeEcheqdirecto(Comitente).pdf'
import EpymeRegistroPyme from '../docs/EpymeRegistroPyme(Comitente).pdf'
import { RiWhatsappFill } from "react-icons/ri";

function PaginaPrincipal() {
    const fade = useSpring({
        from: {
            opacity: 1,
        },
        to: {
            opacity: 1,
            delay: 1500,
        },
    });
    return (
        <animated.div style={fade} className='mt-5'>
            <Navbar />
            <div className='portada'>
                {/* <img src={imgPortada} className='img-fluid' alt="portada" /> */}
                <div className=' sgrDivPortada'>
                    <h5 className='sgrPortada'>
                        SOMOS UNA SOCIEDAD DE GARANTÍA RECÍPROCA CON EL PRINCIPAL OBJETIVO DE PROMOVER
                        EL CRECIMIENTO Y DESARROLLO DE LAS MICRO, PEQUEÑAS Y MEDIANAS EMPRESAS DE NUESTRO
                        PAÍS FACILITANDO PARA ELLO EL ACCESO AL CRÉDITO EN MEJORES CONDICIONES FINANCIERAS
                    </h5>
                </div>
                <div className='row d-flex align-items-center mt-5 sgrBoton'>
                    <a
                        className='btn col-sm-2 col-4 botonSaberMas'
                        href="#abajo"
                    >
                        SABER MÁS
                    </a>
                </div>
            </div>
            <div id='abajo'></div>
            <div className='sgr'>
                <div>
                    <img src={queEsSGR} className="sgrImg" alt="¿QUÉ ES UNA SGR?" />
                </div>
                <div className='sgrInfo'>
                    <div className=''>
                        <h2 className='tituloQueEsSGR mb-5'>¿QUÉ ES UNA SGR?</h2>
                        <p className='parrafoTamaño'>
                            Las Sociedades de Garantía Recíproca o SGRs, son entidades de crédito que facilitan
                            el acceso al financiamiento de pequeñas y medianas empresas, mediante el otorgamiento
                            de garantías de cumplimiento de las obligaciones financieras y/o comerciales de las empresas.
                        </p>
                        <p className='parrafoTamaño parradoSGRVerde'>
                            Las SGRs ofrecen garantías liquidas a los acreedores de las Pymes, de manera que estas puedan
                            acceder a mejores condiciones de tasas y/o plazos de financiamiento. Asimismo, dicha garantía puede
                            ayudar a ampliar la capacidad de endeudamiento de la Pyme, facilitándole el acceso al crédito bancario
                            y/o a los mercados de capitales a través del descuento de valores o emisión de obligaciones negociables pymes.
                        </p>
                        <p className='parrafoTamaño parradoSGRVerde2 '>
                            Gracias al Fondo de Garantía liquido administrado por la SGR, con nuestro AVAL, las PYMEs pueden financiar
                            su capital de trabajo y/o sus inversiones en bienes de uso, más fácilmente, ya que, ante un eventual incumplimiento
                            de las empresas, es la SGR quien responde ante los acreedores de la PYME, eliminando así el riesgo crediticio de las operaciones
                        </p>
                    </div>
                </div>
                <div>
                    <img src={queEsSGR} className="sgrImg2" alt="¿QUÉ ES UNA SGR?" />
                </div>
            </div>
            {/* <br/>
            <div className='servicios'>
                <h2 className='tituloQueEsSGR tituloServicios m-3 p-3'>SERVICIOS</h2>
                <div className="card-group">
                    <div className="card text-center m-2">
                        <img src={imgCredito} className="imgCard card-img-top" alt="Credito" />
                        <div className="card-body">
                            <h5 className="card-title">PRECALIFICACIÓN CREDITICIA</h5>
                            <p className="card-text">Fromulario direccionado a<br />creditos@alianzasgr.com.ar</p>
                            <a href="mailto:creditos@alianzasgr.com.ar"><AiOutlineMail className='iconEmail' /></a>
                        </div>
                    </div>
                    <div className="card text-center m-2">
                        <img src={imgComision} className="imgCard card-img-top" alt="..." />
                        <div className="card-body">
                            <h5 className="card-title">CALCULO DE COMISIONES</h5>
                            <p className="card-text">¿Queremos Mostrarlo?</p>
                            <a href="mailto:creditos@alianzasgr.com.ar"><AiOutlineMail className='iconEmail' /></a>
                        </div>
                    </div>
                    <div className="card text-center m-2">
                        <img src={imgCheques} className="imgCard card-img-top" alt="..." />
                        <div className="card-body">
                            <h5 className="card-title">DESCUENTO DE CHEQUES</h5>
                            <p className="card-text">Pasar .excel del descuento con comisiones para armar el formularios</p>
                            <a href="mailto:creditos@alianzasgr.com.ar"><AiOutlineMail className='iconEmail' /></a>
                        </div>
                    </div>
                </div>
                <div id='testimonios'></div>
            </div> */}
            {/* <div id='nosotros'></div> */}
            <br /><br />
            {/* <div className='my-4'>
                <h2 className='tituloQueEsSGR p-2 mb-4'>NOSOTROS</h2>
                <div className='testiminio row d-flex align-items-center mx-5 p-0'>
                    <div className='col-sm-3 cardPerfil'>
                        <img src={MartinRappallini} class="imgFotoPerfil card-img-top" alt="Foto Martin Rappallini"></img>
                        <h5 className='nombrePerfil'>Martin Rappallini</h5>
                        <h5 className='nombrePerfil'>PRESIDENTE</h5>
                    </div>
                    <div className='col-sm-9 ps-5'>
                        <p className='py-3'>
                            Nacido en Maipú, Provincia de Buenos Aires. Es abogado y master en marketing y cursó la carrera de coaching ontológico.<br />
                            Se crio en una familia ligada a la fabricación de cosméticos, que comenzó como Pyme y creció gracias al trabajo y el compromiso de sus padres.<br />
                            En 1993 adquirieron Cerámica Alberdi, compañía proveedora de la industria de la construcción, que se encontraba en concurso preventivo y que por
                            entonces atravesaba dificultades financieras y estaba tecnológicamente atrasada.<br />
                            Una década después, lograron posicionarla como una empresa líder en el mercado de pisos y revestimientos, rango que mantiene hasta el día de hoy,
                            con plantas industriales en las Provincias de Buenos Aires, Santa Fe y Salta, y exportaciones a más de treinta países.<br />
                            En 2005, atento a la dificultad que enfrentaban las industrias para encontrar terrenos aptos, Martín Rappallini fundó Alberdi Desarrollos SA,
                            dedicada al diseño y comercialización de parques industriales, con el objetivo de ampliar la oferta de terrenos industriales de la más alta
                            calidad internacional, amigables con el medio ambiente y cercanos a la Ciudad de Buenos Aires.<br />
                            Pocos años después nacieron los polos industriales de Ezeiza y Spegazzini, que hoy cuentan con más de 600 hectáreas y, desde 2014, reciben
                            empresas de los más diversos sectores industriales, como calzado, metalúrgica, bebidas, alimentos, química, laboratorio, logística, entre otros.<br />
                            Es Presidente de la Unión Industrial de la Provincia de Buenos Aires (UIPBA), cargo que se sumó al de Presidente del Departamento Pymi y Desarrollo
                            Territorial de la Unión Industrial Argentina (UIA), que ejerció entre 2018 y 2021. Es miembro del Comité Ejecutivo de la UIA desde 2019.<br />
                        </p>
                    </div>
                </div>
                <div className='testiminio row d-flex align-items-center mx-5 mt-3 p-0'>
                    <div className='col-sm-3 cardPerfil'>
                        <img src={RobertoCostaBourdieu} class="imgFotoPerfil card-img-top" alt="Foto Roberto Costa Bourdieu"></img>
                        <h5 className='nombrePerfil'>Roberto Costa Bourdieu</h5>
                        <h5 className='nombrePerfil'>GERENTE GENERAL</h5>
                    </div>
                    <div className='col-sm-9 ps-5'>
                        <p className='py-3'>
                            Estudio Administración de Empresas en la Universidad Católica Argentina, cursó el Master en Mercado Financiero y de Capitales del ESEADE y
                            participó del Advance Management Program (AMP) en el Wharton School of Business, Universidad de Pennsylvania, USA.<br />
                            Trabajó en los departamentos de Tesorería, Research y Asset Management de Banco Rio, Banco Santander y HSBC Bank Argentina.
                            Donde se desempeñó como Analista Senior de Mercados y Gerente de Fondos Comunes de Inversión.<br />
                            En los últimos 15 años se especializó en Sociedades de Garantía Reciproca (SGR). Fue Gerente General de Acindar PYMES SGR. 
                            Subsidiaria de ArcelorMttal Group y empresa líder del sector.<br/>
                            Fundador y Director de Conexión Pyme. Una consultora que no solo brinda asesoramiento en SGRs, sino que busca apoyar a las pymes en 
                            todos aquellos temas relacionados al financiamiento, desarrollo de planes de negocios y outsourcing administrativo entre otros aspectos.<br/>
                            Nacido en Buenos Aires, estudio Administración de Empresas en la Universidad Católica Argentina, cursó el Master en Mercado Financiero y de 
                            Capitales del ESEADE y participó del Advance Management Program (AMP) en el Wharton School of Business, Universidad de Pennsylvania, USA.<br/>
                            Trabajó en los departamentos de Tesorería, Research y Asset Management de Banco Rio, Banco Santander y HSBC Bank Argentina. Donde donde se 
                            desempeño como Analista Senior de Mercados y Gerente de Fondos Comunes de Inversión.<br/>
                            Es entrepreneur, consultor y director de diferentes negocios e industrias.<br/>
                            La última década se especializó en Sociedades de Garantía Reciproca. Fue Gerente General de Acindar PYMES SGR. Subsidiaria de ArcelorMttal Group y empresa líder del sector.<br/>
                        </p>
                    </div>
                </div>
            </div> */}
            <h2 className='tituloQueEsSGR mt-5'>DESCUENTO DE VALORES AVALADOS EN EL MERCADO</h2>
            <div className='valoresAvalados' id='valoresAvalados'>
                <div>
                    <img src={valores} className="valoresImg img-fluid" alt="valores" />
                </div>
                <div >
                    <div className='col-sm-6 mt-5 pymeDiv'>
                        <p className='parrafoTamaño parrafoDiv'>La Pyme envía los cheques de pago diferido (CPDs o Echeqs) a la SGR. <br />
                            Esta los avala y envía a la Caja de Valores, quien los controla y carga en un sistema electrónico para
                            que el Agente de Mercado pueda negociarlos por cuenta y orden de la Pyme.<br />
                            Los inversores compran los CPDs a descuento en un Mercado transparente y competitivo generando así el flujo
                            de dinero que la Pyme necesitaba.
                        </p>
                        <a className='btn btnEpyme' href="https://epyme.cajadevalores.com.ar/login" target='_blank'>
                            <BsGlobe2 /> Epyme Web
                        </a>
                        <a className='btn btnEpyme' href={InstructivoCargaDeEcheqs} target="_blank" rel="noopener noreferrer" download="EpymeIngresodeEcheqdirecto(Comitente).pdf">
                            <BsFileEarmarkPdfFill /> Instructivo Carga de Echeqs
                        </a>
                        <a className='btn btnEpyme' href={EpymeRegistroPyme} target="_blank" rel="noopener noreferrer" download="EpymeRegistroPyme(Comitente).pdf">
                            <BsFileEarmarkPdfFill /> Instructivo de Registro de Pymes
                        </a>
                    </div>
                </div>
            </div>
            <div id='testimonios'></div>
            <br /><br /><br /><br />
            <div>
                <h2 className='tituloQueEsSGR pt-2'>TESTIMONIOS DE CLIENTES</h2>
                <div className='testiminio row d-flex align-items-center m-5'>
                    <div className='col-sm-4'>
                        <h5 className='h5Testimonios' id='Testimonios'>EL SAPO SA</h5>
                    </div>
                    <div className='col-sm-7'>
                        <p>
                            Alianza SGR fue nuestra primer experiencia con SGRs y la verdad que estoy más que contenta. Los chicos muy amables,
                            me explicaron cada paso del proceso y supieron atender perfectamente mis necesidades. Operé con ellos en sus inicios
                            y sigo operando con ellos hoy en día. No me queda más que decir GRACIAS!
                        </p>
                    </div>
                </div>
                <div className='testiminio row d-flex align-items-center m-5'>
                    <div className='col-sm-4'>
                        <h5 className='h5Testimonios'>ARGENTINA CARGO SRL</h5>
                    </div>
                    <div className='col-sm-7'>
                        <p>
                            Muy buena atención. Estoy contento de haberlos elegido.
                        </p>
                    </div>
                </div>
            </div>
            <div className="App">
                <a
                    className='Whatsapp  nav-link p-0'
                    href='https://wa.me/5491152635747'
                    target='_blank'
                    style={{ position: "fixed", bottom: "5%", right: "5%" }}
                >
                    <RiWhatsappFill className='WhatsappIcon' />
                </a>
            </div>
            <Footer />
        </animated.div>
    )
}

export default PaginaPrincipal