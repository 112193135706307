import axios from "axios";


const dataInicial = {
  loading: false,
  activo: false,
  error: false,
  resultado: [],
  resultadoConsulta: ''
}
const LOADING = "LOADING";
const RESULTADO_EXITO = "MENSAJES_EXITO";
const ERROR = "ERROR";

export default function simuladorCheques(state = dataInicial, action) {
    switch (action.type) {
      case LOADING:
        return { ...state, resultadoConsulta: action.resultadoConsulta };
      case RESULTADO_EXITO:
        return { ...state, resultado: action.payload, resultadoConsulta: action.resultadoConsulta };
      case ERROR:
        return { ...dataInicial };
      default:
        return { ...state };
    }
}

export const simuladorCheque = (montoCheque, plazoDias) => async (dispatch) => {
    let cuit = "OneClickSgrSandbox"

    dispatch({
        type: LOADING,
        resultadoConsulta: 'LOADING'
      });

    var entidad = "new_cotizacions"
    var fetch = "<fetch version='1.0' mapping='logical' distinct='false'>" +
    "<entity name='new_cotizacion'>" +
      "<attribute name='new_cotizacionid' />" +
      "<attribute name='new_name' />" +
      "<attribute name='createdon' />" +
      "<attribute name='new_montoneto' />" +
      "<attribute name='new_totalcomisionesygastos' />" +
      "<attribute name='new_montonetoliquidacinacobrar' />" + 
      "<attribute name='new_montoacomisionarporlasgr' />" +
      "<attribute name='new_montoarancelcajadevaloressa' />" + 
      "<attribute name='new_montoarancelagentedemercado' />" +
      "<attribute name='new_montoderechodemercado' />" +
      "<attribute name='new_montoderechodemercado' />" +
      "<attribute name='new_interes' />" +
      "<attribute name='new_importebruto' />" +
      "<attribute name='new_ivasobrecargos' />" +
      "<attribute name='new_descuentoneto' />" +
      "<attribute name='new_derechomercado' />" +
      "<attribute name='new_comisinsgr' />" +
      "<attribute name='new_cftanual' />" +
      "<attribute name='new_arancelcajadevaloressa' />" +
      "<attribute name='new_arancelagentedemercado' />" +
      "<order attribute='new_name' descending='false' />" +
      "<filter type='and'>" +
        "<condition attribute='new_valorcheque' operator='eq' value='1000000' />" +
        "<condition attribute='new_plazo' operator='eq' value='120' />" +
        "<condition attribute='new_esvaloralcobro' operator='eq' value='1' />" +
      "</filter>" +
    "</entity>" +
  "</fetch>";
    try {
        const response = await axios.get(`https://sgroc365api.azurewebsites.net/api/ConsultaFetch?Entidad=${entidad}&fetch=${fetch}&cuit=${cuit}`)
        dispatch({
            type: RESULTADO_EXITO,
            payload: response.data[0],
            resultadoConsulta: 'EXITO'
          });
    }
    catch (error) {
        dispatch({
            type: ERROR,
            resultadoConsulta: 'ERROR'
          });
    }
}