import React from 'react'
import { useSpring, animated } from "react-spring"
import logo from '../img/Logo Vectorizado Horizon.png'
import { Link } from "react-router-dom";

function Navbar() {

    const fade = useSpring({
        from: {
            opacity: 1,
        },
        to: {
            opacity: 1,
            delay: 1500,
        },
    });

    return (
        <animated.div style={fade}>
            <nav className="navbar navbar-expand-lg navbar-light bg-light fixed-top">
                <div className="container-fluid">
                    <a className="navbar-brand col-sm-4 ms-2" href="#"><img className="rounded mx-auto logo-menu" src={logo} alt="Alianza SGR" /></a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                            <li className="nav-item ms-1">
                                <a className="nav-link active" aria-current="page" href="#abajo">Qué es una SGR</a>
                            </li>
                            {/* <li className="nav-item ms-1">
                                <a className="nav-link" href="#nosotros">Nosotros</a>
                            </li> */}
                            <li className="nav-item ms-1">
                                <a className="nav-link" href="#testimonios">Testimonios De Clientes</a>
                            </li>
                            <Link className="text-decoration-none" to="/simulador-de-cheques">
                                <li className="nav-item ms-1">
                                    <a className="nav-link" aria-current="page">Simulador de Cheques</a>
                                </li>
                            </Link>
                            <li className="nav-item ms-1">
                                <a className="nav-link" href="#contacto">Contacto</a>
                            </li>
                            <li className="nav-item ms-1">
                                <a className="nav-link" href="https://socios.alianzasgr.com.ar/" target='_blank'>Portal del Socio</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </animated.div>
    )
}

export default Navbar
