import React from 'react'
import { FcExpand } from "react-icons/fc";
import { AiOutlineMail, AiFillPhone } from "react-icons/ai";
import { SiGooglemaps } from "react-icons/si";
import { RiWhatsappFill } from "react-icons/ri";

function Footer() {
    return (
        <div className='contacto container-fluid' id='contacto'>
            <div className='marginRight'>
                <h2 className='titulo mb-1'>CONTACTO</h2>
            </div>
            <div className='flexCenterColunm marginRight'>
                <h5 className='tituloContacto mt-3'>
                    Oficinas
                </h5>
                <h5 className='datosContacto datosContactoContMail'>
                    <SiGooglemaps />  Av. Corrientes 880, Piso 5to, CABA
                </h5>
            </div>
            <div className='flexCenterColunm marginRight'>
                <h5 className='tituloContacto mt-3'>
                    Correo Electrónico
                </h5>
                <h5 className='datosContacto datosContactoContMail'>
                    <AiOutlineMail /> creditos@alianzasgr.com.ar
                </h5>
            </div>
            <div className='flexCenterColunm marginRight'>
                <h5 className='tituloContacto mt-3'>
                    Número de Telefono
                </h5>
                <h5 className='datosContacto datosContactoCell'>
                    <AiFillPhone /> (+54)1152635747
                </h5>
                <h5 className='d-flex justify-content-start m-0 mb-3'>
                    <a className='Whatsapp datosContacto datosContactoCell nav-link p-0' href='https://wa.me/5491152635747' target='_blank'>
                        <RiWhatsappFill /> Whatsapp
                    </a>
                </h5>
            </div>
            <div>
                <a className='flexCenter' href="http://qr.afip.gob.ar/?qr=8kaEN4zqtNcnwhU4ITah_A,," target="_F960AFIPInfo">
                    <img className='LogoAFIP' src="http://www.afip.gob.ar/images/f960/DATAWEB.jpg" border="0" />
                </a>
            </div>
        </div>
    )
}

export default Footer