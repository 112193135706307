import {createStore, combineReducers, compose, applyMiddleware} from 'redux'
import thunk from 'redux-thunk'
import simuladorCheques from '../Redux/simuladorCheques'


const appReducer = combineReducers({
  cheque: simuladorCheques,
 })

 const rootReducer = (state, action) => {
    // when a logout action is dispatched it will reset redux state
    if (action.type === 'CERRAR_SESION') {
      state = undefined;
    }
  
    return appReducer(state, action);
  };

 const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default function generateStore(){
    const store = createStore(rootReducer, composeEnhancers( applyMiddleware(thunk) ) )
    return store
}