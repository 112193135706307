import React from "react";
import "./App.css";
import PaginaPrincipal from "./page/PaginaPrincipal";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Simulador from "./page/SimuladorCheques.jsx";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<PaginaPrincipal />} />
        <Route path="/simulador-de-cheques" element={<Simulador />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
